import { useSelector } from "react-redux";
import "./App.css";
import Home from "./Home";

function App() {
  const { c } = useSelector((state) => state.customReducer);

  return (
    <div className="App">
      <h2>from App = {c}</h2>
      <Home />
    </div>
  );
}

export default App;
