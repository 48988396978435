import { createAction, createReducer } from "@reduxjs/toolkit";

const increment = createAction("increment");
const decrement = createAction("decrement");
const incrementByValue = createAction("incrementByValue");

const initialState = {
  c: 0,
};

const customReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(increment, (state) => {
      state.c += 1;
    })
    .addCase(decrement, (state) => {
      state.c -= 1;
    })
    .addCase(incrementByValue, (state, action) => {
      state.c += action.payload;
    });
});

// write better code for the above reducer

export default customReducer;
