import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const { c } = useSelector((state) => state.customReducer);

  const dispatch = useDispatch();
  const addBtn = () => {
    dispatch({
      type: "increment",
    });
  };

  const addBtn25 = () => {
    dispatch({
      type: "incrementByValue",
      payload: 25,
    });
  };

  const subBtn = () => {
    dispatch({
      type: "decrement",
    });
  };

  return (
    <div>
      <h1>Home Page</h1>

      <h2>{c}</h2>
      <p>
        <button onClick={addBtn}>Increment</button>
        <button onClick={subBtn}>Decrement</button>{" "}
        <button onClick={addBtn25}>Increment by 25</button>{" "}
      </p>
    </div>
  );
};

export default Home;
