import { configureStore } from "@reduxjs/toolkit";
import customReducer from "./Reducers";

const Store = configureStore({
  reducer: {
    customReducer: customReducer,
  },
});

export default Store;
